.affirm-payment-method.form-row {
	width: 90%;
}

.affirm-payment-method.form-row .field-wrapper{
	width: 100%;
}

.affirm-payment-method.form-row .field-wrapper .field-label{
	display: inline-block;
	vertical-align: middle;
}

.payment-description-logo-affirm{
	width: 4.5rem;
}

.affirm-as-low-as {
	height: 3.25rem;
	padding-bottom: 1rem;
}

.affirm-promo-empty {
	padding-bottom: 3rem;
}

.affirm-payment-method.form-row .field-wrapper .field-label span{
	vertical-align: 70%;
}

.affirm-payment-method.form-row .field-wrapper .field-label a{
	display: inline-block;
	vertical-align: 67%;
}